import "../styles/Contact.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  faInstagram,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function Contact() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const socialMediaLinks = [
    {
      name: "LinkedIn",
      icon: faLinkedin,
      link: "https://www.linkedin.com/company/metaupspace",
    },
    {
      name: "Instagram",
      icon: faInstagram,
      link: "https://www.instagram.com/metaupspace/",
    },
    {
      name: "Twitter",
      icon: faXTwitter,
      link: "https://twitter.com/metaupspace",
    },
    { name: "Email", icon: faEnvelope, link: "mailto:metaupspace@gmail.com" },
  ];

  return (
    <>
      <section className="get-in-touch-section">
        <Container>
          <Row className="justify-content-center align-items-center vh-100">
            <Col lg={10} className="text-center">
              <h1>Get in touch with us</h1>
              <div className="d-flex justify-content-center mt-5">
                {socialMediaLinks.map((socialMedia, index) => (
                  <Button
                    key={index}
                    href={socialMedia.link}
                    target="_blank"
                    rel="noreferrer"
                    className="mx-2 px-4 py-2 rounded-pill"
                  >
                    <FontAwesomeIcon icon={socialMedia.icon} />{" "}
                    {socialMedia.name}
                  </Button>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Contact;
