import React from "react";
import "./Navbar.scss";
import { Nav, Navbar} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import MenuIcon from "../../assets/images/menu_icon.png";

/* Set the width of the sidebar to 25rem  (show it) */
function openNav() {
  document.getElementById("sidepanel").style.width = "100vw";
  document.getElementById("overlay").style.width = "100vw";
}

function NavbarComponent() {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" fixed="top" className="sticky px-5">
        <Navbar.Brand href="/">
          <span
            style={{
              fontFamily: "Naston Regular",
              color: "var(--primary-color)",
              fontWeight: "500",
            }}
          >
            MetaUpSpace
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ml-auto">
            <NavLink to="/" className="nav-link">
              Home
            </NavLink>
            <NavLink to="/about" className="nav-link">
              About
            </NavLink>
            <NavLink to="/services" className="nav-link">
              Our Services
            </NavLink>
            <NavLink
              to="https://api.whatsapp.com/send/?phone=%2B918689829680&text=Hi%2C+I+would+like+to+know+more"
              className="nav-link schedule-button"
            >
              Schedule a Call
              <i className="bi bi-whatsapp ms-2"></i>
            </NavLink>
          </Nav>
        </Navbar.Collapse>
        <NavLink className="sidebar-btn ms-5">
          <img src={MenuIcon} alt="Menu" onClick={() => openNav()} className="sideMenuBtn" />
        </NavLink>
      </Navbar>
    </>
  );
}

export default NavbarComponent;
