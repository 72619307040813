import React from "react";
import { Container, Row } from "react-bootstrap";

function NotFound() {
  return (
    <>
      <Container>
        <Row className="justify-content-center align-items-center vh-100">
          <h1 className="display-1 fw-bolder text-center">404 Not Found</h1>
        </Row>
      </Container>
    </>
  );
}

export default NotFound;
