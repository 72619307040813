import React, { useEffect } from "react";
import "../styles/About.scss";
import { Container, Row, Col, Button } from "react-bootstrap";
import CEO from "../assets/images/ceo.png";
import CTO from "../assets/images/cto.png";
import CMO from "../assets/images/cmo.png";
import TeamDiscussion from "../assets/images/team-discussion.png";
import Kunal from "../assets/images/kunal.png";
import Nitesh from "../assets/images/nitesh.png";
import Kamran from "../assets/images/kamran.png";
import Person1 from "../assets/images/person1.png";
import Person2 from "../assets/images/person2.png";

function About() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <header
        className="py-5"
        style={{
          backgroundColor: "var(--bg-secondary-color)",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <p
                className="fs-5 mt-5 text-center"
                style={{ color: "var(--text-secondary-color)" }}
              >
                We are not just another agency. We are{" "}
                <span
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  MetaUpSpace
                </span>
                , a passionate collective of tech enthusiasts, storytellers, and
                marketing strategists who believe in the power of digital
                experiences to transform businesses.
              </p>
            </Col>
          </Row>
        </Container>
      </header>
      <section className="py-5" id="what-sets-us-apart">
        <div className="apart-container rounded-5 mx-5">
          <Row className="justify-content-center px-5">
            <Col lg={12} className="px-5 mx-5">
              <h2 className=" display-4 fw-bolder mt-5">
                What Sets{" "}
                <span
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Us
                </span>{" "}
                Apart?
              </h2>
              <div
                className="text-wrap my-5 w-75"
                style={{ color: "var(--text-secondary-color)" }}
              >
                <p className="fs-6 my-4">
                  Holistic Approach: We don't compartmentalize. We seamlessly
                  blend tech, marketing, and production, creating a symphony of
                  digital experiences that resonate deeply with your audience.
                </p>
                <p className="fs-6 my-4">
                  Data-Driven Decisions: We're not fans of guesswork. We
                  leverage data and analytics to ensure every decision is
                  informed, every pixel is optimized, and every campaign
                  delivers measurable results.
                </p>
                <p className="fs-6 my-4">
                  Client-Centric Collaboration: We believe in true partnership.
                  We work closely with you, understanding your vision, your
                  challenges, and your dreams, to craft solutions that perfectly
                  align with your goals.
                </p>
                <p className="fs-6 my-4">
                  Passionate Storytellers: We're not just tech geeks or
                  marketing gurus. We are storytellers, weaving narratives that
                  capture hearts and minds, building communities, and driving
                  brand loyalty.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="mt-5" id="our-team">
        <Container>
          <Row className="justify-content-between">
            <Col lg={6}>
              <Row className="my-3 justify-content-center">
                <Col lg={4}>
                  <img
                    src={Kunal}
                    alt="Kunal"
                    border="0"
                    className="bg-white"
                  />
                </Col>
                <Col lg={4}>
                  <img
                    src={Kamran}
                    alt="kamran"
                    border="0"
                    className="bg-white"
                  />
                </Col>
                <Col lg={4}>
                  <img
                    src={Nitesh}
                    alt="Nitesh"
                    border="0"
                    className="bg-white"
                  />
                </Col>
              </Row>
              <Row className="my-3 justify-content-around">
                <Col lg={4}>
                  <img
                    src={Person1}
                    alt="person1"
                    border="0"
                    className="bg-white"
                  />
                </Col>
                <Col lg={4}>
                  <img
                    src={Person2}
                    alt="person2"
                    border="0"
                    className="bg-white"
                  />
                </Col>
                <Col lg={4}>
                  <img
                    src={Person1}
                    alt="person1"
                    border="0"
                    className="bg-white"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={5}>
              <h2 className=" display-4 fw-bolder mt-5">
                Meet <s>Our</s> Your{" "}
                <span style={{ color: "var(--primary-color)" }}>Team?</span>
              </h2>
              <div
                className="text-wrap my-5 w-75"
                style={{ color: "var(--text-secondary-color)" }}
              >
                <p
                  className="fs-6 my-4"
                  style={{
                    color: "var(--text-secondary-color)",
                  }}
                >
                  Born from the collision of cutting-edge technology, compelling
                  storytelling, and data-driven marketing, we exist to empower
                  brands to thrive in the ever-evolving digital landscape. We
                  don't settle for cookie-cutter solutions; we tailor every
                  pixel, every line of code, and every story to your unique
                  brand identity and aspirations.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="my-5 py-5" id="our-founder">
        <Container className="rounded-5">
          <Row className="justify-content-center align-items-center">
            <Col lg={6}>
              <h1 className="display-4">Sahil Jaiswal</h1>
              <h6
                style={{
                  color: "var(--primary-color)",
                }}
              >
                Founder & CEO
              </h6>
              <p
                style={{
                  color: "var(--text-secondary-color)",
                }}
              >
                I'm not a CEO, I'm a storyteller. Every project is a chapter,
                every campaign a verse. We weave narratives that captivate,
                inspire, and ignite action. Because in the end, it's not about
                selling products, it's about selling dreams, and who wouldn't
                want to be a part of that?
              </p>
            </Col>
            <Col lg={4}>
              <img
                src={CEO}
                alt="Sahil Jaiswal"
                border="0"
                className="img-fluid h-100 w-100"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="my-5 py-5" id="our-cofounders">
        <Container>
          <Row className="justify-content-evenly">
            <Col lg={4} className="cofounder-card  rounded-5">
              <img
                src={CTO}
                alt="Mayur Hiwale"
                border="0"
                className="w-100 rounded-top-3"
              />
              <h2 className="h2 mt-3">Mayur Hiwale</h2>
              <h6
                style={{
                  color: "var(--primary-color)",
                }}
              >
                CTO
              </h6>
              <p
                style={{
                  color: "var(--text-secondary-color)",
                }}
              >
                The only limit in this world is imagination. My tools are
                keyboards and compilers, my playground the infinite expanse of
                the digital universe. And I'm just getting started.
              </p>
            </Col>
            <Col lg={4} className="cofounder-card rounded-5">
              <img
                src={CMO}
                alt="Simran Makhija"
                border="0"
                className="w-100 rounded-top-3"
              />
              <h2 className="h2 mt-3">Simran Makhija</h2>
              <h6
                style={{
                  color: "var(--primary-color)",
                }}
              >
                CMO
              </h6>
              <p
                style={{
                  color: "var(--text-secondary-color)",
                }}
              >
                Every click, every conversion, is a testament to the fire that
                burns within me - the fire to leave a digital legacy.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5" id="work-with-us">
        <Container className="px-5 my-5">
          <Row className="justify-content-between align-items-center">
            <Col lg={4}>
              <img
                src={TeamDiscussion}
                alt="team-dissussion"
                border="0"
                className="h-100 w-100 my-auto"
              />
            </Col>
            <Col lg={6} className="d-flex-column justify-content-center">
              <h3 className="my-2">
                Get a Chance to{" "}
                <span
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Work with Us
                </span>
              </h3>
              <p
                className="fs-6 mb-5"
                style={{
                  color: "var(--text-secondary-color)",
                }}
              >
                We are always looking for talented individuals to join our team.
              </p>
              <Button
                className="share-your-cv-btn text-decoration-none mb-5 w-50 px-4 py-2"
                href="#!"
              >
                Share your CV / Portfolio
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default About;
