import React, { useEffect } from "react";
import "../styles/Home.scss";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, EffectFade } from "swiper/modules";
import AOS from "aos";
import "aos/dist/aos.css";

import GDSC from "../assets/brands/GDSC.png";
import TechnicalSapien from "../assets/brands/TechnicalSapien.png";
import Barefox from "../assets/brands/BareFox.png";
import MetaInfluencer from "../assets/brands/Meta Influencer.png";

import WorkingOnLaptop from "../assets/images/working-on-laptop.png";
import DataAnalysis from "../assets/images/data-analysis.png";

import HeroSocialVector from "../assets/images/hero-social-vector.png";

import QuantumXShield from "../assets/projects/Quantum X shield.gif";
import OlichaHotel from "../assets/projects/Olicha Hotel and Restaurant.gif";

import TestimonialPerson1 from "../assets/images/cto.png";
function Home() {
  const Testimonials = [
    {
      name: "Ashutosh Pratap Singh",
      desgination: "Founder, Technical Sapien (800K+ Followers)",
      description:
        "I am deeply grateful for MetaUpSpace's exceptional  Service for AI development & community management services. Your team not only expertly handles our vast 500,000+ member community (India's biggest!), but also impresses you with your dedication, and commitment,  Your innovative solutions have been invaluable. I highly recommend MetaUpSpace for anyone seeking to build a Product",
      image: TestimonialPerson1,
    },
    {
      name: "Samarth Mahabia",
      desgination: "Youtuber, Barefox (150k+ Subscriber)",
      description:
        "Finding MetaUpSpace was a game-changer! Their editing services and content strategy have propelled my channel to new heights. Their professionalism, knowledge, and willingness to go the extra mile are outstanding. If you're looking for a trustworthy partner in your content creation journey, MetaUpSpace is the answer. Thanks for being the best!",
      image: TestimonialPerson1,
    },
    {
      name: "Harsh Kothari",
      desgination: "Product Designer, Unacademy",
      description:
        "MetaUpSpace stands out for its dedication to cutting-edge Web 3 technology. Witnessing their passion for innovation has been awe-inspiring. Their top-notch web development, smart contract development, and AI chatbot solutions make them a reliable tech partner. I have no doubt that MetaUpSpace will continue to deliver exceptional results.",
      image: TestimonialPerson1,
    },
    {
      name: "Shivay Lamba",
      desgination: "Founder, Darkhorse",
      description:
        "I have had the pleasure of working with the team at MetaupSpace for our company Darkhorse. They have provided us with excellent support for the development of our web application They have been a valuable member of our team and I would highly recommend his and his team's technical expertise to anyone looking for reliable and high-quality integration support.",
      image: TestimonialPerson1,
    },
  ];

  const Projects = [
    {
      name: "Quantum x Shield",
      description:
        "Quantum X Shield is a beacon in the realm of cybersecurity, committed to fortifying digital landscapes against the ever-evolving threats of the cyber world.",
      image: QuantumXShield,
      link: "https://quantumxshield.com/",
    },
    {
      name: "Olicha Hotel and Restaurant",
      description: "Olicha Hotel and Restaurant : Best Restaurant In Karimganj",
      image: OlichaHotel,
      link: "https://olicha.co.in/",
    },
  ];

  useEffect(() => {
    // Scroll to top
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // AOS
    AOS.init({
      offset: 300,
      once: false,
    });
  }, []);

  return (
    <>
      <div className="blue-element"></div>
      <header className="hero-section">
        <ul className="list-unstyled justify-content-center d-flex flex-column hero-social-icons">
          <img src={HeroSocialVector} className="hero-social-vector-1" alt="" />
          <li className="my-1">
            <a
              href="https://in.linkedin.com/company/metaupspace"
              target="_blank"
              rel="noreferrer"
              className="social-icon text-decoration-none"
            >
              <i className="bi bi-linkedin"></i>
            </a>
          </li>
          <li className="my-1">
            <a
              href="https://www.instagram.com/metaupspace"
              target="_blank"
              rel="noreferrer"
              className="social-icon text-decoration-none"
            >
              <i className="bi bi-instagram"></i>
            </a>
          </li>
          <li className="my-1">
            <a
              href="#!"
              target="_blank"
              rel="noreferrer"
              className="social-icon text-decoration-none"
            >
              <i className="bi bi-twitter"></i>
            </a>
          </li>
          <img src={HeroSocialVector} className="hero-social-vector-2" alt="" />
        </ul>
        <Row className="vh-100">
          <Col
            lg={12}
            className="mx-5 px-5 hero-content d-flex flex-column justify-content-center"
          >
            <h1 className="hero-title">MetaUpSpace</h1>
            <h4 className="hero-subtitle">
              Where{" "}
              <span style={{ color: "var(--primary-color)" }}>Innovation</span>{" "}
              meets Impact
            </h4>
            <hr className="hero-divider1" />
            <div className="hero-description d-flex align-items-center">
              <p className="px-2">
                Unleash your brand potential with MetaUpSpace: Result-driven
                solutions for Tech, marketing and production.
              </p>
            </div>
            <hr className="hero-divider-two" />
            <div className="hero-description-two d-flex align-items-center">
              <p className="px-2">
                From chatbots that engage to brands that scale, we empower your
                brand to thrive in the digital age.
              </p>
            </div>
          </Col>
        </Row>
      </header>
      <section className="my-5 py-5" id="brands">
        <Row>
          <Col
            lg={3}
            className="d-flex justify-content-center align-item-center"
          >
            <img src={TechnicalSapien} alt="Technical Sapien" />
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-item-center"
          >
            <img src={GDSC} alt="GDSC" />
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-item-center"
          >
            <img src={Barefox} alt="Barefox" />
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-item-center"
          >
            <img src={MetaInfluencer} alt="Meta Influencer" />
          </Col>
        </Row>
      </section>
      <section className="my-5 py-5" id="features">
        <Row className="mx-5 my-5">
          <Col lg={6}>
            <h1 className="fw-bolder">
              Why People{" "}
              <span style={{ color: "var(--primary-color)" }}>Believe</span> in
              Us
            </h1>
          </Col>
          <Col lg={6}>
            <p
              className="fs-5 fw-bolder"
              style={{
                color: "var(--text-secondary-color)",
              }}
            >
              Always render more and better service than <br />
              is expected of you, no matter what your ask may be.
            </p>
          </Col>
        </Row>
        <Row className="px-5 gx-5">
          <Col lg={3}>
            <div className="feature bg-gradient text-white rounded-3 mb-3">
              <i className="bi bi-collection"></i>
            </div>
            <h2 className="h4 fw-bolder">Unique Selling Proposition</h2>
            <p
              style={{
                color: "var(--text-secondary-color)",
              }}
            >
              We constantly refine and update our software and marketing
              strategies to keep your business ahead of the competition.
              <br /> We understand that effective positioning isn't just about
              how you see your brand but also about how your customers perceive
              it.
              <br /> Our cost-effective solutions provide maximum value for your
              investment.
            </p>
          </Col>
          <Col lg={3}>
            <div className="feature bg-gradient text-white rounded-3 mb-3">
              <i className="bi bi-building"></i>
            </div>
            <h2 className="h4 fw-bolder">Featured Case Studies</h2>
            <p
              style={{
                color: "var(--text-secondary-color)",
              }}
            >
              Our proven track record speaks volumes about our commitment to
              delivering high-quality software and effective marketing
              strategies.
            </p>
          </Col>
          <Col lg={3}>
            <div className="feature bg-gradient text-white rounded-3 mb-3">
              <i className="bi bi-toggles2"></i>
            </div>
            <h2 className="h4 fw-bolder">Call to Action / Get Quote</h2>
            <p
              style={{
                color: "var(--text-secondary-color)",
              }}
            >
              Join the revolution! Partner with us to build a future ! Call us
              now !
            </p>
          </Col>
          <Col lg={3}>
            <div className="feature bg-gradient text-white rounded-3 mb-3">
              <i className="bi bi-toggles2"></i>
            </div>
            <h2 className="h4 fw-bolder">Additional Elements</h2>
            <p
              style={{
                color: "var(--text-secondary-color)",
              }}
            >
              Your success is our priority. We pride ourselves on understanding
              your business goals and aligning our software and marketing
              services to help you achieve them. "We're not just a software
              company, we're your partner in digital success."
            </p>
          </Col>
        </Row>
      </section>
      <section
        className="vh-100 d-flex align-items-center"
        id="founded-section"
      >
        <Container className="px-5 py-5 rounded-5">
          <Row className="my-5 py-5 gx-5">
            <Col lg={10}>
              <h1 className="my-5 display-5 fw-bolder text-white">
                Founded in 2023 by tech and marketing experts to help brands
                thrive digitally through innovative solutions.
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5" id="connect-to-us">
        <Container className="px-5 my-5">
          <Row className="justify-content-around">
            <Col lg={5} className="d-flex flex-column justify-content-center">
              <h1 className="my-5 fw-bolder">
                <span
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Connect
                </span>{" "}
                to us and !
              </h1>

              <Button
                className="share-your-query-btn text-decoration-none mb-5 w-50 px-4 py-2"
                target="_blank"
                href="https://tally.so/r/mB7k95"
              >
                Share your Query
              </Button>
            </Col>
            <Col lg={6}>
              <h3 className="my-5 text-end">
                Connect To us Through Our Social Media Handles Or Directly{" "}
                <span
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Mail Us At
                </span>
              </h3>
              <ul className="list-unstyled d-flex justify-content-end social-icons">
                <li className="mx-2">
                  <a
                    href="#!"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <i className="bi bi-twitter"></i>
                  </a>
                </li>
                <li className="mx-2">
                  <a
                    href="https://www.instagram.com/metaupspace"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                </li>
                <li className="mx-2">
                  <a
                    href="https://in.linkedin.com/company/metaupspace"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <i className="bi bi-linkedin"></i>
                  </a>
                </li>
                <li className="mx-2">
                  <a
                    href="#!"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <i className="bi bi-envelope"></i>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5" id="projects-section">
        <Container className="px-5 my-5">
          <Row>
            <Col lg={6}>
              <h1 className="mb-3">
                Some of Our{" "}
                <span style={{ color: "var(--primary-color)" }}>Projects</span>{" "}
                Works
              </h1>
              <a href="#!" className="text-white text-decoration-none">
                View All Projects <i className="bi bi-arrow-right-circle"></i>
              </a>
            </Col>
            <Col lg={6} className="text-start">
              <h6 className="lead" style={{ color: "var(--primary-color)" }}>
                Revolutionizing industries with cutting-edge tech. Here are some
                of them
              </h6>
            </Col>
          </Row>
        </Container>
        <Swiper
          slidesPerView={2}
          spaceBetween={50}
          loop={true}
          navigation={true}
          modules={[Navigation]}
          className="mx-5 px-5"
        >
          {Projects.map((project, index) => (
            <SwiperSlide key={index}>
              <div className="project-card py-4 mx-3 rounded-3 bg-grey d-flex flex-column align-items-center justify-content-center">
                <img
                  src={project.image}
                  alt={project.name}
                  className="rounded-2 h-100 mb-3"
                />
                <div className="text-start px-3">
                  <h3>
                    <a
                      href={project.link}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none text-white fw-bolder"
                    >
                      {project.name}
                    </a>
                  </h3>
                  <p>{project.description}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <section className="my-5" id="message">
        <Row className="justify-content-evenly align-items-center mx-5">
          <Col lg={8}>
            <p
              className="lead fw-bolder text-center mb-5"
              style={{
                color: "var(--text-secondary-color)",
              }}
            >
              Born from the collision of cutting-edge technology, compelling
              storytelling, and data-driven marketing, we exist to empower
              brands to thrive in the ever-evolving digital landscape. We don't
              settle for cookie-cutter solutions; we tailor every pixel, every
              line of code, and every story to your unique brand identity and
              aspirations.
            </p>
          </Col>
          <Col lg={4}>
            <img
              src={WorkingOnLaptop}
              alt="Working on laptop"
              className="img-fluid"
            />
          </Col>
        </Row>
      </section>
      <section className="py-5" id="our-work-method">
        <Row className="justify-content-between mx-5">
          <Col lg={3}>
            <h1 className="fw-bolder">
              Our 7 Step Work{" "}
              <span
                style={{
                  color: "var(--primary-color)",
                }}
              >
                Process
              </span>
            </h1>
          </Col>
          <Col lg={9}>
            <p
              className="fs-5 fw-bolder"
              style={{
                color: "var(--text-secondary-color)",
              }}
            >
              We are MetaUpSpace, your one-stop shop for tech-powered growth. We
              blend innovation, marketing, and production to create experiences
              that captivate your audience and drive results.
            </p>
          </Col>
        </Row>
        <Row
          className="justify-content-evenly align-items-center mx-5"
          style={{}}
        >
          <Col lg={3}>
            <img src={DataAnalysis} alt="Data Analysis" className="img-fluid" />
          </Col>
          <Col
            lg={9}
            className="rounded-5 px-5"
            style={{
              backgroundColor: "var(--bg-secondary-color)",
            }}
          >
            <h2 className="h2 my-5 fw-bolder">Process We Follow</h2>
            <div
              style={{
                color: "var(--text-secondary-color)",
              }}
            >
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Requirements Gathering
                </strong>{" "}
                - Consult with clients to determine project goals, target users,
                necessary features, and other key details.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Design &amp; Planning
                </strong>{" "}
                - Create sitemaps, user flows, wireframes and other technical
                specifications to clearly map out the solution.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Development
                </strong>{" "}
                - Program the software and build a working prototype with
                iterative testing. Agile methods help incorporate feedback.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Testing
                </strong>{" "}
                - Thoroughly assess functionality, user experience, security,
                integrations and performance. Identify any defects.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Deployment
                </strong>{" "}
                - Launch the software for client/user access after final
                testing. Migrate data, provide user training as needed.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Maintenance
                </strong>{" "}
                - Provide ongoing support addressing bugs, server issues,
                feature requests, and other post-deployment needs.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Review &amp; Iterate
                </strong>{" "}
                - Revisit software usefulness and features. Ppan upgrades and
                new capabilities for the next release.
              </p>
            </div>
          </Col>
        </Row>
      </section>
      <section className="testimonial-section my-5">
        <Row className="mx-5 px-5">
          <h1>
            What our Customer{" "}
            <span
              style={{
                color: "var(--primary-color)",
              }}
            >
              Say About Us?
            </span>
          </h1>
        </Row>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          navigation={true}
          loop={true}
          effect="fade"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Autoplay, EffectFade]}
          className="d-flex justify-content-center align-items-center"
        >
          {Testimonials.map((testimonial, index) => (
            <SwiperSlide key={index} className="testimonial py-5 rounded-5">
              <Row className="justify-content-center align-items-center">
                <Col lg={5} className="testimonial-text rounded-5 py-5 px-5">
                  <h1 className="text-white">{testimonial.name}</h1>
                  <h5
                    style={{
                      color: "var(--primary-color)",
                    }}
                  >
                    {testimonial.desgination}
                  </h5>
                  <blockquote className="blockquote border-0 p-0">
                    <p className="fst-italic lead">
                      <FontAwesomeIcon
                        icon={faQuoteLeft}
                        className="fa-xs"
                      ></FontAwesomeIcon>{" "}
                      <span>{testimonial.description}</span>{" "}
                      <FontAwesomeIcon
                        icon={faQuoteRight}
                        className="fa-xs"
                      ></FontAwesomeIcon>
                    </p>
                  </blockquote>
                </Col>
                <Col
                  lg={5}
                  className="d-flex align-items-center justify-content-center"
                >
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="rounded-5"
                  />
                </Col>
              </Row>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  );
}

export default Home;
