import { Row, Col } from "react-bootstrap";
import "./Sidebar.scss";
import { NavLink } from "react-router-dom";
import MenuIcon from "../../assets/images/menu_icon.png";

/* Set the width of the sidebar to 0 (hide it) */
function closeNav() {
  document.getElementById("sidepanel").style.width = "0";
  document.getElementById("overlay").style.width = "0";
}

function Sidebar() {
  return (
    <>
      <Row
        className="sidepanel justify-content-around align-items-center"
        id="sidepanel"
      >
        <Col lg={5}>
          <NavLink onClick={() => closeNav()} to="/" className="sidebar-brand">
            <h1>MetaUpSpace</h1>
          </NavLink>
          <NavLink className="closebtn" onClick={() => closeNav()}>
            <img src={MenuIcon} alt="menu" />
          </NavLink>
          <NavLink onClick={() => closeNav()} className="smenu" to="/">
            Home
          </NavLink>
          <NavLink onClick={() => closeNav()} className="smenu" to="/contact">
            Contact Us
          </NavLink>
          <NavLink onClick={() => closeNav()} className="smenu" to="/about">
            About Us
          </NavLink>
          <NavLink onClick={() => closeNav()} className="smenu" to="/services">
            Our Services
          </NavLink>
        </Col>
        <Col lg={5} className="sidepanel-section-2">
          <div className="my-3 ">
            <h1>Address:</h1>
            <p>
              SN park, Belpada Kharghar, Navi Mumbai, Maharashtra 410210, India
              <br />
              📍Remote is what we work mostly
            </p>
          </div>
          <div className="my-3">
            <h1>Contact:</h1>
            <p>
              <a
                href="mailto:metaupspace@gmail.com"
                className="text-decoration-none text-white"
              >
                metaupspace@gmail.com
              </a>
            </p>
            <p>
              <a
                href="tel:+91 8689829680"
                className="text-decoration-none text-white"
              >
                +91 8689829680
              </a>
            </p>
          </div>
          <div className="my-5 text-center">
            <NavLink
              to="https://api.whatsapp.com/send/?phone=%2B918689829680&text=Hi%2C+I+would+like+to+know+more"
              className="nav-link schedule-button py-2 px-4 rounded-3"
            >
              Schedule a Call
              <i className="bi bi-whatsapp ms-2"></i>
            </NavLink>
          </div>
        </Col>
      </Row>
      <div className="overlay" id="overlay"></div>
    </>
  );
}

export default Sidebar;
