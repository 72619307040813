import "./App.scss";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "tailwindcss/tailwind.css";
import $ from "jquery";
import "jquery-ui/dist/jquery-ui.min";
import DisableDevtool from "disable-devtool";

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";

import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Sidebar from "./components/sidebar/Sidebar";
import NotFound from "./pages/404NotFound";

function App() {
  useEffect(() => {
    // Prevents the user from inspecting the page
    document.onkeydown = (e) => {
      if (e.key === "F12") {
        alert(
          "This function has been disabled to prevent you from stealing my code!"
        );
        return false;
      }
      if (
        (e.ctrlKey && e.shiftKey && e.key === "I") ||
        (e.ctrlKey && e.shiftKey && e.key === "J") ||
        (e.ctrlKey && e.shiftKey && e.key === "C") ||
        (e.ctrlKey && e.key === "U")
      ) {
        alert(
          "This function has been disabled to prevent you from stealing my code!"
        );
        e.preventDefault();
      }
    };
    DisableDevtool();

    $(window).on("scroll", function () {
      $(window).scrollTop() >= 150
        ? $(".sticky").addClass("stickyadd")
        : $(".sticky").removeClass("stickyadd");
    });
  }, []);
  return (
    <>
      <Navbar />
      <Sidebar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
