import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import "../styles/Services.scss";

import OurServices from "../assets/images/ourservices.png";

import AIDevelopment from "../assets/services/ai-development.png";
import MobileAppDevelopment from "../assets/services/mobile-app-development.png";
import SoftwareDevelopment from "../assets/services/software-development.png";
import BusinessAutomation from "../assets/services/business-automation.png";
import ProductDevelopment from "../assets/services/product-development.png";
import AugmentedReality from "../assets/services/augmented-reality.png";
import VideoProduction from "../assets/services/2d-3d-video-production.png";
import Cinematography from "../assets/services/cinematography.png";
import VideoEditing from "../assets/services/video-editing.png";
import ColorGrading from "../assets/services/color-grading.png";
import GraphicDesign from "../assets/services/graphic-design.png";
import MotionGraphics from "../assets/services/motion-graphics.png";
import Branding from "../assets/services/branding.png";
import CommunityBuilding from "../assets/services/community-building.png";
import PromotionAdvertising from "../assets/services/promotion-advertising.png";
import ValuableContentCreation from "../assets/services/valuable-content-creation.png";

function Services() {
  const [isAllServiceLoaded, setIsAllServiceLoaded] = useState(false);

  const handleAllServiceButtonClick = () => {
    setIsAllServiceLoaded(!isAllServiceLoaded);
  };

  useEffect(() => {
    AOS.init({
      offset: 275,
      once: false,
    });
  }, []);

  return (
    <>
      <section className="mt-5 py-5">
        <Container className="mt-5">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h1 className="display-5 mb-5">
                <span
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Features
                </span>{" "}
                We Provide To Our Customer
              </h1>
              <p
                className="text-start"
                style={{ color: "var(--text-secondary-color)" }}
              >
                We create unique and tailor-made website designs that reflect
                the client's brand identity and effectively communicate their
                message to the target audience.
              </p>
            </Col>
            <Col lg={5}>
              <img src={OurServices} alt="Our Services" className="w-100" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="mt-5" id="services-section">
        <Row className="mx-5 px-5 justify-content-between">
          <Col lg={3}>
            <h1
              style={{
                color: "var(--primary-color)",
              }}
            >
              Services
            </h1>
          </Col>
          <Col lg={3}>
            <h5 className="text-end">
              <a
                className="viewServiceBtn text-decoration-underline "
                style={{ color: "var(--text-secondary-color)" }}
                href="#Tech&Production-container"
                onClick={handleAllServiceButtonClick}
              >
                {isAllServiceLoaded ? "View Less Services" : "View All Services"}
                <i className="bi bi-arrow-right ms-2"></i>
              </a>
            </h5>
          </Col>
        </Row>
        <Container className="my-5">
          <h3>Development:</h3>
          <Row className="mt-4">
            <Col lg={3}>
              <img src={AIDevelopment} alt="" />
              <h5 className="mt-3">AI Development</h5>
            </Col>
            <Col lg={3}>
              <img src={MobileAppDevelopment} alt="" />
              <h5 className="mt-3">Mobile App Development</h5>
            </Col>
            <Col lg={3}>
              <img src={SoftwareDevelopment} alt="" />
              <h5 className="mt-3">Software Development</h5>
            </Col>
            <Col lg={3}>
              <img src={BusinessAutomation} alt="" />
              <h5 className="mt-3">Business Automation</h5>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={3}>
              <img src={ProductDevelopment} alt="" />
              <h5 className="mt-3">Product Development</h5>
            </Col>
          </Row>
        </Container>
        {isAllServiceLoaded && (
          <>
            <Container className="mt-5" id="Tech&Production-container">
              <h3>Tech & Production:</h3>
              <Row className="mt-4">
                <Col lg={3}>
                  <img src={AugmentedReality} alt="" />
                  <h5 className="mt-3">Augmented Reality</h5>
                </Col>
                <Col lg={3}>
                  <img src={VideoProduction} alt="" />
                  <h5 className="mt-3">2D/3D Video Production</h5>
                </Col>
                <Col lg={3}>
                  <img src={Cinematography} alt="" />
                  <h5 className="mt-3">Cimenatography</h5>
                </Col>
                <Col lg={3}>
                  <img src={VideoEditing} alt="" />
                  <h5 className="mt-3">Video Editing</h5>
                </Col>
              </Row>
            </Container>
            <Container className="mt-5">
              <h3>Design:</h3>
              <Row className="mt-4">
                <Col lg={3}>
                  <img src={GraphicDesign} alt="" />
                  <h5 className="mt-3">Graphic Design</h5>
                </Col>
                <Col lg={3}>
                  <img src={MotionGraphics} alt="" />
                  <h5 className="mt-3">Motion Graphics</h5>
                </Col>
                <Col lg={3}>
                  <img src={ColorGrading} alt="" />
                  <h5 className="mt-3">Color Grading</h5>
                </Col>
              </Row>
            </Container>
            <Container className="mt-5">
              <h3>Branding & Marketing:</h3>
              <Row className="mt-4">
                <Col lg={3}>
                  <img src={Branding} alt="" />
                  <h5 className="mt-3">Branding</h5>
                </Col>
                <Col lg={3}>
                  <img src={PromotionAdvertising} alt="" />
                  <h5 className="mt-3">Promotion & Advertising</h5>
                </Col>
                <Col lg={3}>
                  <img src={CommunityBuilding} alt="" />
                  <h5 className="mt-3">Community Building</h5>
                </Col>
                <Col lg={3}>
                  <img src={ValuableContentCreation} alt="" />
                  <h5 className="mt-3">Valuable Content Creation</h5>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </section>
      <section className="my-5 pt-5">
        <Row className="justify-content-evenly mt-5">
          <Col lg={5} className="services-detail-card rounded-4 px-5 py-4">
            <h2 className="h2 my-5 fw-bolder">
              <span
                style={{
                  fontFamily: "Naston Regular",
                  color: "var(--primary-color)",
                }}
              >
                MetaUpSpace
              </span>{" "}
              Development
            </h2>
            <div
              style={{
                color: "var(--text-secondary-color)",
              }}
            >
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Web Development
                </strong>{" "}
                - Craft a unique online identity that resonates with your
                audience.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Mobile App Development
                </strong>{" "}
                - Build a mobile app that engages your customers and keeps them
                coming back.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Software Development
                </strong>{" "}
                - Create a custom software solution that streamlines your
                business processes.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Business Automation
                </strong>{" "}
                - Automate your business processes to save time and increase
                efficiency.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Product Development
                </strong>{" "}
                - Bring your product idea to life with a custom software
                solution.
              </p>
            </div>
          </Col>
          <Col lg={5} className="services-detail-card rounded-4 px-5 py-4">
            <h2 className="h2 my-5 fw-bolder">
              <span
                style={{
                  fontFamily: "Naston Regular",
                  color: "var(--primary-color)",
                }}
              >
                MetaUpSpace
              </span>{" "}
              Tech & Production
            </h2>
            <div
              style={{
                color: "var(--text-secondary-color)",
              }}
            >
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Augmented Reality
                </strong>{" "}
                - Create an immersive experience that brings your brand to life.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  2D/3D Video Production
                </strong>{" "}
                - Tell your story with a video that engages your audience.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Cinematography
                </strong>{" "}
                - Capture your audience's attention with a cinematic video.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Video Editing
                </strong>{" "}
                - Turn your raw footage into a professional video.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-evenly mt-5">
          <Col lg={5} className="services-detail-card rounded-4 px-5 py-4">
            <h2 className="h2 my-5 fw-bolder">
              <span
                style={{
                  fontFamily: "Naston Regular",
                  color: "var(--primary-color)",
                }}
              >
                MetaUpSpace
              </span>{" "}
              Design
            </h2>
            <div
              style={{
                color: "var(--text-secondary-color)",
              }}
            >
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Graphic Design
                </strong>{" "}
                - Create a visual identity that resonates with your audience.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Motion Graphics
                </strong>{" "}
                - Bring your brand to life with motion graphics.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Color Grading
                </strong>{" "}
                - Enhance the look of your video with color grading.
              </p>
            </div>
          </Col>
          <Col lg={5} className="services-detail-card rounded-4 px-5 py-4">
            <h2 className="h2 my-5 fw-bolder">
              <span
                style={{
                  fontFamily: "Naston Regular",
                  color: "var(--primary-color)",
                }}
              >
                MetaUpSpace
              </span>{" "}
              Branding & Marketing
            </h2>
            <div
              style={{
                color: "var(--text-secondary-color)",
              }}
            >
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Branding
                </strong>{" "}
                - Create a brand that resonates with your audience.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Promotion & Advertising
                </strong>{" "}
                - Promote your brand with a video that engages your audience.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Community Building
                </strong>{" "}
                - Build a community around your brand.
              </p>
              <p>
                <strong
                  style={{
                    color: "var(--primary-color)",
                  }}
                >
                  Valuable Content Creation
                </strong>{" "}
                - Create valuable content that resonates with your audience.
              </p>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}

export default Services;
