import React from "react";
import "./Footer.scss";
import { Col, Container, Row } from "react-bootstrap";

function Footer() {
  return (
    <>
      <Container className="footer-container px-5 py-5 rounded-5">
        <Row className="gx-5">
          <Col lg={4}>
            <h3 className="fw-bolder" style={{ color: "var(--primary-color)" }}>
              Address
            </h3>
            <p>
              SN park, Belpada Kharghar, Navi Mumbai, Maharashtra 410210, India
              <br />
              📍Remote is what we work mostly
            </p>
          </Col>
          <Col lg={4}>
            <h3 className="fw-bolder" style={{ color: "var(--primary-color)" }}>
              Contact Us
            </h3>
            <p>
              <a
                href="mailto:metaupspace@gmail.com"
                className="text-decoration-none text-white"
              >
                metaupspace@gmail.com
              </a>
            </p>
            <p>
              <a
                href="tel:+91 8689829680"
                className="text-decoration-none text-white"
              >
                +91 8689829680
              </a>
            </p>
          </Col>
          <Col lg={4}>
            <h3 className="fw-bolder" style={{ color: "var(--primary-color)" }}>
              Social Media
            </h3>
            <ul className="list-unstyled d-flex justify-content-start social-icons">
              <li className="mx-2">
                <a href="#!" target="_blank" rel="noreferrer" className="text-decoration-none text-white fs-5">
                  <i className="bi bi-twitter"></i>
                </a>
              </li>
              <li className="mx-2">
                <a href="https://www.instagram.com/metaupspace" target="_blank" rel="noreferrer" className="text-decoration-none text-white fs-5">
                  <i className="bi bi-instagram"></i>
                </a>
              </li>
              <li className="mx-2">
                <a href="https://in.linkedin.com/company/metaupspace" target="_blank" rel="noreferrer" className="text-decoration-none text-white fs-5">
                  <i className="bi bi-linkedin"></i>
                </a>
              </li>
              <li className="mx-2">
                <a href="mailto:metaupspace@gmail.com" target="_blank" rel="noreferrer" className="text-decoration-none text-white fs-5">
                  <i className="bi bi-envelope"></i>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="footer mt-5 py-3">
        <Row className="justify-content-evenly">
          <Col lg={6}>
            <span
              style={{
                fontFamily: "Naston Regular",
              }}
            >
              MetaUpSpace
            </span>
          </Col>
          <Col lg={6} className="text-end">
            <p>©2023 All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Footer;
